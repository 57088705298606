import "./resources.scss"

import React, { useState } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
  getGatsbyImage,
} from "../plugins/common"

const Banner = loadable(() => import("../components/Banner"))
const Browse = loadable(() => import("../components/resources/Browse"))
const Featured = loadable(() => import("../components/resources/Featured"))
const Guides = loadable(() => import("../components/resources/Guides"))
const HealthyMatters = loadable(() =>
  import("../components/resources/HealthyMatters")
)
const Slogan = loadable(() => import("../components/Slogan"))

const resourceHeaderLeft = "../images/resources/resourceHeaderLeft.webp"
const resourceHeaderRight = "../images/resources/resourceHeaderRight.webp"
const resourceHeaderFull = "../images/resources/resourceHeaderFull.webp"

const browseLeft = "../images/resources/browseLeft.webp"
const browseRight = "../images/resources/browseRight.webp"
const bannerShape = "../images/resources/bannerShape.webp"
const ResourcesPage = ({ pageContext, location }) => {
  const { resources: data, categories: category, general, form } = pageContext
  const categories = category.filter(c => c.type === "resource")
  const locale = data.locale
  const keyLabels = general.allStrapiRessource.nodes.find(
    node => node.locale === locale
  )
  const aleaToken = general.aleaToken

  const headerImage1 = getGatsbyImage(data.headerImage.image[0])
  const headerImage2 = getGatsbyImage(data.headerImage.image[1])
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerImage.image[0])
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  const [headerSearchKeyword, setHeaderSearchKeyword] = useState(null)
  if (location.state && location.state.searchKeyword) {
    setHeaderSearchKeyword(location.state.searchKeyword)
    delete location.state.searchKeyword
  }

  return (
    <Layout
      token={aleaToken}
      props={pageContext.general}
      meta={data.meta}
      localizations={getAllLocales(data.localizations, data.locale)}
      location={location}
      lang={lang}
      ogImage={ogImage}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="overflow-hidden">
        <Slogan props={data.headerImage} lang={lang}>
          <div id="left" className="resourceHeader position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="left position-absolute"
              src={resourceHeaderLeft}
              alt=""
              layout="constrained"
            />
          </div>
          <div id="right" className="resourceHeader position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="right position-absolute"
              src={resourceHeaderRight}
              alt=""
              layout="constrained"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="full position-absolute"
              src={resourceHeaderFull}
              alt=""
              layout="constrained"
            />

            <div className="imageZoom d-unset">
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="image1 imageAni position-absolute"
                image={headerImage1}
                alt="alea"
              />
            </div>
            <div className="imageZoom d-unset">
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="image2 imageAni position-absolute"
                image={headerImage2}
                alt="alea"
              />
            </div>
          </div>
        </Slogan>
        <div className="resourceFeatured">
          <Featured
            props={data.featuredArticles}
            categories={categories}
            labels={keyLabels}
            lang={lang}
          />
        </div>

        <div className="resourceBrowse position-relative">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="browseLeft position-absolute"
            src={browseLeft}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="browseRight position-absolute"
            src={browseRight}
            alt=""
            layout="constrained"
          />
          <Browse
            props={data.browse}
            headerSearchKeyword={headerSearchKeyword}
            categories={categories}
            lang={lang}
            labels={keyLabels}
            token={aleaToken}
          />
        </div>
        <Guides
          props={data.guides}
          categories={categories}
          labels={keyLabels}
          lang={lang}
        />
        <div className="resourceHM position-relative">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="bannerShape position-absolute"
            src={bannerShape}
            alt=""
            layout="constrained"
          />
          <HealthyMatters
            props={data.banner}
            articles={data.healthyMattersArticle}
            categories={categories}
            labels={keyLabels}
          />
        </div>
        <Banner props={data.banner2} lang={lang} />
      </div>
    </Layout>
  )
}

export default ResourcesPage
